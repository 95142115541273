let API_URL = 'https://inris-online.com/api';

if (process.env.NODE_ENV === "development") {
  //API_URL = 'http://127.0.0.1:8000/api';
  API_URL = 'https://inris-online.com/api';
}
if (process.env.NODE_ENV === "production") {
  if(window.location.hostname === "preprod-customers.inris-online.com") {
    API_URL = 'https://inris-online.com/api';
  }
  if(window.location.hostname === "customers.inris-online.com") {
    API_URL = 'https://inris-online.com/api';
  }
}
/**config API_URL */
const config = {
  apiUrl: API_URL,
};

export default config;
