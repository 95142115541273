import React, { Component } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import './App.scss';
import './tableBlock.scss';
import Loadable from 'react-loadable';
import Firewall from './services/redirectors/Firewall';
import Loading from './services/common/loading';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Token, Ids } from './config/';
import Store from '../src/redux/store';

//loading
const loading = Loading
// DefaultLayout
const DefaultLayout = Loadable({
  loader: () => import('./components/containers/DefaultLayout'),
  loading
});
// Login
const Login = Loadable({
  loader: () => import('./components/connexion/login'),
  loading
});
// Logout
const Logout = Loadable({
  loader: () => import('./components/connexion/logout'),
  loading
});

//app class
class App extends Component {
  render(props) {
    let persistor = persistStore(Store,
      null,
      () => {
        const { token, idMoniteur, role } = Store.getState().userReducer;
        if (token !== null) { Token.setAccessToken(token) }
        if (idMoniteur !== null) { Ids.setIdMoniteur(idMoniteur) }
        if (role !== null) { Ids.setRole(role) }
      },
    );
    return (


      <Provider store={Store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/logout" component={Logout} />
              <Firewall>
                <Route path="/" name="Home" component={DefaultLayout} />
              </Firewall>
            </Switch>
          </BrowserRouter>
        </PersistGate>
      </Provider>

);
  }
}
export default App;