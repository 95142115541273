import { configureStore } from '@reduxjs/toolkit'
import userReducer from './reducers'
import {combineReducers} from "redux"; 
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const reducers = combineReducers({
  userReducer: userReducer,
});
const persistConfig = {
     key: 'root',
     storage
 };
const persistedReducer = persistReducer(persistConfig, reducers);
const Store = configureStore({
     reducer: persistedReducer,
     devTools: process.env.NODE_ENV !== 'production',
 });

 if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./reducers', () => {
    const newRootReducer = require('./reducers').default
    Store.replaceReducer(newRootReducer)
  })
}
export default Store;
