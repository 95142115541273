class Ids {

    setIdMoniteur(idMoniteur) {
        this.idMoniteur = idMoniteur;
    }

    getIdMoniteur() {
        return this.idMoniteur;
    }

    setRole(role) {
        this.role = role;
    }
    getRole() {
        return this.role;
    }

}

export default new Ids();
