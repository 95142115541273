import { createSlice } from '@reduxjs/toolkit';
import { stat } from 'fs';
import { Token } from '../../config/';

export const userSlice = createSlice({
  name: "user",
  initialState: {
    isLogged: false,
    user: null,
    token: null,
    loggedAt: null,
    idMoniteur: null,
    role: null,
    //new
    idCustomer: null,
    idAgency: null,
    idAgenda : null,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isLogged = action.payload.isLogged;
      state.loggedAt = action.payload.loggedAt;
      state.idMoniteur = action.payload.idMoniteur;
      state.role = action.payload.role;
      //new
      state.idCustomer = action.payload.idCustomer;
      state.idAgenda = action.payload.idAgenda;

    },
  },
});

export const { setUser } = userSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const loadUserAsync = (data, idMoniteur, role, idCustomer, idAgenda) => dispatch => {
  Token.setAccessToken(data.authToken.token);
  const user = {
    user: data.user,
    token: data.authToken.token,
    isLogged: true,
    loggedAt: Date.now(),
    role: role,
    idMoniteur: idMoniteur,
    idCustomer : idCustomer,
    idAgenda : idAgenda
  }
  setTimeout(() => {
    dispatch(setUser(user));
  }, 1000);
};

export const logoutUser = () => dispatch => {
  const user = {
    isLogged: false,
    user: null,
    token: null,
    loggedAt: null,
    idMoniteur: null,
    role: null,
    idCustomer : null,
    idAgenda: null
  }
  dispatch(setUser(user));
}

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectUser = state => state.userReducer.user;
export const selectToken = state => state.userReducer.token;
export const selectIsLogged = state => state.userReducer.isLogged;
export const selectLoggedAt = state => state.userReducer.loggedAt;
export const selectIdMoniteur = state => state.userReducer.idMoniteur;
export const selectRole = state => state.userReducer.role;
//new
export const selectIdCustomer = state => state.userReducer.idCustomer;
export const selectIdAgency = state => state.userReducer.idAgency;
export const selecteIdAgenda = state => state.userReducer.idAgenda;

export default userSlice.reducer;
