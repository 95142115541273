import React from 'react';
import { Redirect } from 'react-router-dom';
import { selectIsLogged, selectLoggedAt } from '../../redux/reducers';
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

const Firewall = (props) => {
  const loggedAt = useSelector(selectLoggedAt);
  const isLogged = useSelector(selectIsLogged);

  if (isLogged && loggedAt + 3600000 > Date.now()) {
    return (props.children);
  } else {
    return (<Redirect to="/logout" />);
  }
}

export default withRouter(
  Firewall
);
