import { axiosConfig } from '.'
import moment from "moment/moment";

const apiCall = {
  
  /****************************************************  Common routes ****************************************************/
  /* Login */ 
  postLogin(email, password) {
    return axiosConfig.requestLogin("POST", "/auth-tokens/instructor", {
      email,
      password
    });
  },

  /* mot de passe oublié */
  postPwdForgotten(email) {
    return axiosConfig.requestLogin("POST", "/instructor/pwd/forgotten", {
      email,
    });
  },

  /* Récuperer le token d'un utilisateur */
  getUserByToken(token) {
    return axiosConfig.request("POST", "/laposte/login", {
      laposteIntegrationToken: token
    });
  },

  /* Récuperer la liste des RDV d'un moniteur */
  getAppointmentListInstructor(idcustomer, role, idinstructor, idagenda){
    return axiosConfig.request("GET", `/instructor/meetings/${idcustomer}/${role}/${idinstructor}/${idagenda}`);
  },

  /* Récuperer la lite des élèves d'un moniteur */
  getStudentsListInstructor(idcustomer, role, idinstructor, idagenda){
    return axiosConfig.request("GET", `/instructor/participants/${idcustomer}/${role}/${idinstructor}/${idagenda}`);
  },

  /* Récuperer les crédit temps d'un élève */
  getCreditTempsListInstructor(idcustomer, role, idinstructor, idagenda, idstudent){
    return axiosConfig.request("GET", `/instructor/participant/timecredit/${idcustomer}/${role}/${idinstructor}/${idagenda}/${idstudent}`);
  },

  /* La liste des participants dans un rendez-vous */
  getMeetingParticipantsListInstructor(idcustomer, role, idinstructor, idagenda, idmeeting){
    return axiosConfig.request("GET", `/meeting/participants/${idcustomer}/${role}/${idinstructor}/${idagenda}/${idmeeting}`);
  },

  /* Récuperer les informations d'un moniteur */
  getInstructorInfos(idcustomer, role, idinstructor){
    return axiosConfig.request("GET", `/instructor/infos/${idcustomer}/${role}/${idinstructor}`);
  },

  /* Récuperer le nombre des élèves du mois courant */
  getNbrMonthEleves(idcustomer, role, idinstructor, idagenda){
    return axiosConfig.request("GET", `/instructor/participants/month/${idcustomer}/${role}/${idinstructor}/${idagenda}`);
  },

  /* le taux horaire de moniteur */
  getTauxHour(){
    return axiosConfig.request("GET", `/`);
  },

  /* Modifier les informations d'un moniteur */
  editInstructorInfos(idCustomer, role, user, iAgenda, userInfos) {
    const sexe = userInfos.sexe;
    const speaker_firstname = userInfos.firstName;
    const speaker_lastname = userInfos.lastName;
    const speaker_email = userInfos.email;
    const speaker_mobile = userInfos.phoneNumber;
    const birthdate = moment(userInfos.birthdate).format("YYYY-MM-DD");
    const auth_inst_city = userInfos.auth_inst_city;
    const auth_inst_date = moment(userInfos.auth_inst_date).format("YYYY-MM-DD");
    const auth_inst_number = userInfos.auth_inst_number;
    const medical_auth_inst_date = moment(userInfos.medical_auth_inst_date).format("YYYY-MM-DD");
    const adresse = {
      street: userInfos.street ? userInfos.street : '3 Rue Jules Guesde',
      postal_code: userInfos.postalCode ? userInfos.postal_code : '91130',
      city: userInfos.city ? userInfos.city : 'Ris-Orangis',
      country: 'FRANCE'
    };
    const hourlyRate = userInfos.hourlyRate;
    return axiosConfig.requestSend("PATCH", `/instructor/infos/${idCustomer}/${role}/${user}/${iAgenda}`, {
      sexe,
      speaker_firstname,
      speaker_lastname,
      speaker_email,
      speaker_mobile,
      birthdate,
      auth_inst_city,
      auth_inst_date,
      auth_inst_number,
      medical_auth_inst_date,
      adresse, 
      hourlyRate
    });
  },

  /* Uploader une image */
   uploadImage(id, role, idinstructor, avatar) {
    return axiosConfig.requestSendFile("POST", `/instructor/upload/image/${id}/${role}/${idinstructor}`, {
      avatar,
    }
    );
  },

  postAddInstructorSchedule(id, role, idagenda, schedule) {
    return axiosConfig.requestSend("POST", `/customer/interface/instructor/add/schedule/${id}/1/${idagenda}`, {
      schedule
    })
  },

};
// ./ end

export default apiCall


